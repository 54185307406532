.dropdown-wrapper {
    margin-top: 20px;
    min-width: 235px;
}
.dropdown-content {
    border-radius: 25px;
    border: 1px solid #093566;
    height: 40px;
    padding-left: 20px;
    padding-top: 5px;
    font-size: 16px;
    text-align: left;
    cursor: pointer;
}

.dropdown-header {
    display: flex;
    justify-content: space-between;
}

.dropdown-menu-header {
    padding-left: 20px;
    margin-bottom: 10px;
}

.dropdown-angle {
    padding-right: 20px;
}

.dropdown-list {
    margin-bottom: 10px;
    background-color: #FAFAFA;
    position: relative;
    text-align: left;
    padding-left: 10px;
    left: -10px;
    width: 210px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
    padding-bottom: 10px;
    z-index: 1;
}

.dropdown-list-item {
    padding-top: 10px;
}

.dropdown-list-item:hover {
    background-color: #E4E8ED;
    position: relative;
    left: -10px;
    padding-left: 10px;
    width: 210px;
}

.dropdown-without-border-content {
    height: 40px;
    padding-left: 2px;
    padding-top: 5px;
    font-size: 16px;
    text-align: left;
    cursor: pointer;
}

.dropdown-without-border-angle {
    padding-right: 64px;
}

.without-border-list {
    width: 185px;
}

.without-border-item {
    width: 185px!important;
}