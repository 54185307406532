.task-format-outer-container {
    display: flex;
    margin-top: 5px;
}

.generator-criteria-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}
