#tripleDotMenu {
    font-size: 30px;
    color: #093566;
    margin-left: 15px;
}

.modal-options__container {
    width: 150px;
    position: absolute;
    right: -50px;
    top: 44px;
    background-color: #FAFAFA;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    padding: 20px 20px 20px 20px;

}

.options-list li {
    margin-bottom: 10px;
    text-decoration: underline;
    font-size: 16px;
    cursor: pointer;
}

.hidden {
    display: none;
}
