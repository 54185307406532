.subtasks {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-bottom: 20px;
}

.subtasks > div {
    display: flex;
    justify-content: space-between;
}
