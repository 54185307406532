.task-format-container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-right: 80px;
    cursor: pointer;
}

.task-format-box-big {
    width: 40px;
    height: 40px;
    margin: 10px;
    border-radius: 10px;
    background-color: #7687A4;
}

.task-format-box-small {
    width: 10px;
    height: 10px;
    margin: 10px;
    background-color: #7687A4;
}

.format-header-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.format-header {
    padding-left: 20px;
}
