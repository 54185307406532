.toggle-button {
    display: flex;
}

.toggle-button-option:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left: 1px solid #093566;
}

.toggle-button-option:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.toggle-button-option {
    text-align: center;
    padding-top: 10px;
    border: 1px solid #093566;
    border-left: none;
    height: 40px;
    margin-top: 20px;
}

.toggle-button-checked {
    background-color: #093566;
    color: #FFFFFF;
}

.toggle-button-header-container {
    display: flex;
    flex-direction: row;
}
