body, button, input, select, textarea, p {
    font-family: Verdana, sans-serif;
    font-size: 14px;
    color: #093566;
}

button, input[type=checkbox], input[type=submit], select, input[type=file] + label {
    cursor: pointer;
}

button {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    -webkit-appearance: none;
    font-family: inherit;
}

.fullHeight {
    height: 100%;
}

@media screen and (min-width: 1408px) {
    .container {
        height: 100%;
        color: #093566;
        width: 100%;
        min-width: 352px;
        max-width: 1408px;
    }
}

.floatRight {
    float: right;
}

.floatLeft {
    float: left;
}

.smallHeader {
    font-size: 16px;
    padding: 1em 0 1em 0;
    color: #006060;
}

.bigHeader {
    font-size: 30px;
    color: #093566;
}

.lightText {
    font-weight: 100;
    font-size: 14px;
    color: inherit;
    opacity: 0.72;
}

.strongText {
    font-weight: 400;
    font-size: 18px;
    color: inherit;
    opacity: 1;
}

.main-content {
    padding-left: 38px;
}

.non-selectable {
    user-select: none;
}

/* newTaskModal.css*/

.pageinator {
    font-size: 10px;
    color: #FBB829;
}

.pager {
    padding-right: 10px;
    margin-left: 4px;
}

.new-task-modal__header {
    margin-top: 14px;
    margin-bottom: 14px;
    text-align: center;
}

.new-task-modal__header i {
    color: #B8C5D0;
}

.modal-bottom-button-row {
    margin-top: 20px;
    padding-bottom: 50px;
}

.modal-bottom__button button {
    height: 40px;
    border-radius: 25px;
    font-size: 14px;
    border: 1px solid #B8C5D0;
}

.modal-bottom__button--next {
    background-color: #5E9E21;
    color: #fff;
    margin-left: 20px;
    width: 138px;
}

.modal-bottom__button--save {
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
}

.modal-bottom__button--remove {
    width: 138px;
}

.modal-bottom__button--previous {
    width: 150px;
}

.modal-bottom__button--publish {
    background-color: #5E9E21;
    color: #fff;
    margin-left: 20px;
    width: 200px;
}

.chevron-icon-right {
    padding-left: 18px;
    padding-top: 2px;
    position: absolute;
}

.publish-icon {
    padding-right: 10px;
}

.padding-right10 {
    padding-right: 10px;
}

.text-input {
    border: 1px solid #B8C5D0;
    border-radius: 25px;
    width: 100%;
    height: 40px;
    padding-left: 20px;
    font-size: 16px;
    outline: none;
}

.text-input--logo {
    padding-left: 34px;
}

.title-input-label--modal {
    padding-left: 20px;
}

.textarea-input {
    max-width: 100%;
    width: 100%;
    border-radius: 6px;
    resize: vertical;
    height: 100px;
    border: 1px solid #B8C5D0;
    padding: 10px;
    font-size: 14px;
    outline: none;
}

.add-subtask-row {
    cursor: pointer;
}

.subtask_close {

}

.padding-left10 {
    padding-left: 10px;
}

.task-set-new-task-set__button {
    border-radius: 25px;
    border: 1px solid #B8C5D0;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    height: 50px;
}

.task-set-new-task-set__button--label {
    font-size: 16px;
    padding-left: 40px;
    align-self: center;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.task-set-new-task-set__button:before {
    content: url("resources/customIcons/addToPlusGreen.svg");
    position: absolute;
    margin-top: 10px;
    padding-left: 6px;
}

.select-input select:hover {
    background-color: rgba(0, 0, 0, 0.02);
}

.select-input--task-set {
    height: 50px;
    font-size: 16px;
}

.select-input--grade {
    height: 40px;
    font-size: 14px;
}

.select-input select {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #B8C5D0;
    border-radius: 25px;
    width: 100%;
    cursor: pointer;
    background-color: #fff;
    overflow: hidden;
    padding-left: 20px;
}

.select-input select:focus {
    outline: none;
}

.select-input:after {
    font-family: FontAwesome, serif;
    content: '\f107';
    position: absolute;
    font-size: 30px;
    margin-left: -42px;
    pointer-events: none;
}

.type-input__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    justify-content: space-between;
}

.type-container {
    margin-top: 30px;
}

.checkbox--label {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    max-width: 160px;
}

.checkbox-modal {
    font-size: 16px;
}

.checkbox-modal--difficulty {
    width: 50px !important;
}

.checkbox-modal--difficulty span {
    padding-left: 10px;
}

.checkbox-modal--type {
    margin-top: 18px;
}

.checkbox-modal--topic {
    margin-top: 8px;
}

.checkbox-filter:after {

}

.checkbox {
    width: 100%;
}

.checkbox span {
    align-self: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.checkbox input {
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkbox input[type=checkbox]:after,
.checkbox input[type=radio]:after {
    font-family: FontAwesome, serif;
    font-size: 22px;
    color: #093566;
    padding-left: 8px;
}

.checkbox input[type=radio]:after {
    content: '\f1db';
}

.checkbox input[type=checkbox]:after {
    content: '\f096';
}

.checkbox input[type=checkbox]:checked:after,
.checkbox input[type=radio]:checked:after {
    color: #5E9E21;
    padding-left: 8px;
}

.checkbox input[type=radio]:checked:after {
    content: '\f058';
}

.checkbox input[type=checkbox]:checked:after {
    content: '\f14a';
}

.difficulty-input__container {
    margin-top: 13px;
}

.difficulty--checkbox {
    display: flex;
    justify-content: space-between;
    max-width: 200px;
}

.topic-input__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    justify-content: space-between;
}

.topic-checkbox__container {
    margin-top: 14px;
}

.placeholder-icon--calculator:before {
    font-family: FontAwesome, serif;
    content: '\f1ec';
    position: absolute;
    padding-left: 13px;
    margin-top: 11px;
}

.placeholder-icon--link:before {
    font-family: FontAwesome, serif;
    content: '\f0c1';
    position: absolute;
    padding-left: 13px;
    margin-top: 11px;
}

.error-message-label {
    padding-left: 20px;
    color: red;
    position: relative;
}

.extra-spacing {
    padding-top: 20px;
    padding-bottom: 20px;
}

/* React-confirm-alert css */

.react-confirm-alert > h1 {
    margin-top: 0;
    color: #093566;
}

.react-confirm-alert > h3 {
    margin: 0;
    font-size: 16px;
    color: #093566;
}

.react-confirm-alert-button-group {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.react-confirm-alert-button-group > button {
    height: 40px;
    border-radius: 25px;
    font-size: 14px;
    color: #fff;
    background-color: #333;
    margin: auto;
    width: 150px;
}

/* end newTaskModal.css*/

/* task.css */

.task__container {
    height: 231px;
    width: 352px;
    border: 1px solid #B8C5D0;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    background-color: #fff;
    cursor: pointer;
}

.task__container--selected,
.task__container:hover {
    border-color: #5E9E21;
    box-shadow: rgba(94, 158, 33, 0.24);
}

.task__container__content {
    padding-left: 50px;
    padding-right: 25px;
}

.bottom-taskbar {
    height: 40px;
    margin-top: 16px;
    background-color: #FAFAFA;
}

.task-preview-button {
    border: 1px solid #B8C5D0;
    padding: 3px 12px 3px 12px;
    border-radius: 25px;
    background-color: #fff;
}

.task-preview-button__container {
    position: absolute;
    margin-top: 7px;
    margin-left: 5px;
    cursor: pointer;
}

.task-hover__container {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;

}

.task-preview-task-hover {
    position: absolute;
    width: 500px;
    box-shadow: 0 7px 9px 0 rgba(0, 0, 0, 0.44);
}

#taskIcon {
    font-size: 19px;
}

.fa.fa-circle-thin {
    font-size: 22px;
    cursor: pointer;
}

.fa.fa-check-circle {
    font-size: 22px;
    color: #5E9E21;
    cursor: pointer;
}

.task-right-corner--icon {
    float: right;
    padding: 8px 8px 0 0;
}

.right-bottom--icon {
    float: right;
    padding: 10px 15px 15px 0;
    cursor: pointer;
    font-size: 20px !important;
}

.task-title--icon {
    position: absolute;
    margin: 32px 0 0 20px;
    font-size: 19px;
}

.task-title p {
    margin-top: 35px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    width: 252px;
}

.task-sub-title span {
    padding-top: 10px;
    height: 52px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.task-categories {
    padding-top: 34px;
    height: 58px;
    overflow: hidden;
}

.task-categories::-webkit-scrollbar {
    background: transparent;
}

.category__container {
    height: 25px;
    width: fit-content;
    border: solid #B8C5D0;
    border-radius: 19px;
    float: left;
    margin-right: 5px;
    border-width: 1px 1px 2px 1px;
}

.category-text {
    text-align: center;
    font-size: 13px;
    height: 16px;
    line-height: 20px;
    padding: 0 8px 0 8px;
}

.task-list__element {
    padding-bottom: 30px;
}

.empty-box {
    width: 70%;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    background-color: #fff;
    margin: auto;
}

.empty-box__wrapper {
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
}

.empty-box__icon img {
    width: 100px;
    height: 120px;
    margin: 40px 0 -10px 0;
}

.empty-box__message {
    width: 50%;
    margin: auto;
    padding-bottom: 40px;
}

.empty-box__message h1 {
    font-size: 30px;
    margin-bottom: 10px;
}

.task-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 352px);
    justify-content: space-between;
}

/* end task.css */

/*TaskSet.css*/

.task-set-count__container {
    height: 52px;
}

.task-set-title--icon {
    position: absolute;
    margin: 36px 0 0 20px;
    font-size: 19px;
}

.task-count {
    height: 25px;
    width: fit-content;
    display: inline-block;
    border-radius: 19px;
    background-color: #EBF0F3;
}

.task-set-count-text {
    float: right;
    font-size: 15px;
    margin-right: 10px;
    margin-left: 5px;
    line-height: 22px;
}

#taskCountIcon {
    margin-left: 12px;
    font-size: 15px;
}

.fa.fa-circle-thin {
    font-size: 22px;
    cursor: pointer;
}

.fa.fa-check-circle {
    font-size: 22px;
    color: #5E9E21;
    cursor: pointer;
}

.fa.fa-files-o {
    font-size: 19px;
}

.left-bottom--icon {
    float: left;
    padding: 10px 15px 15px 0;
    margin-left: 20px;
}

.task-set-rating {
    float: left;
    padding: 10px 15px 15px 0;
}

.fa.fa-star {
    font-size: 22px;
    color: #F5A623;
}

.task-set-title p {
    margin-top: 35px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    width: 252px;
}

.task-set-categories {
    padding-top: 34px;
    height: 110px;
    overflow: hidden;
}

.chosen-task-list-single-task {
    width: fit-content;
    margin: auto;
}

/* end taskSet.css*/

/* modal.css */

/*Task modal*/

.modal-content {
    width: 1196px;
}

.modal-background {
    background-color: rgba(10, 10, 10, .60);
}

.modal-content__container {
    min-width: 500px;
    background-color: #FAFAFA;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    padding: 20px 20px 20px 20px;
}

.modal-content__topbar {
    margin-bottom: 40px;
}

.modal-topbar-button__container {
    position: absolute;
    right: 40px;
}

.topbar-rating {
    display: flex;
    line-height: 19px;
}

.icon-margin {
    margin-right: 10px;
}

.modal-properties__container {
    margin-top: 20px;
}

.modal-category__container {
    height: 25px;
    width: fit-content;
    border: 1px solid #B8C5D0;
    border-radius: 19px;
    float: left;
    margin-right: 5px;
}

.property-row {
    width: 100%;
}

.modal-row {
    margin-top: 20px;
}

.share-link-label {
    line-height: 36px;
    width: 100%;
    height: 40px;
    border: 1px solid #B8C5D0;
    border-radius: 20px;
    background-color: #FFF;
    padding-left: 24px;
    cursor: pointer;
}

.share-link-label:before {
    font-family: FontAwesome, serif;
    content: '\f0c1';
    padding-right: 10px;
    font-size: 18px;
}

.share-link-text {
    border-left: 1px solid #B8C5D0;
    width: fit-content;
    margin-left: 20px;
    padding-left: 20px;
    line-height: 26px;
}

.add-to-collection__button {
    height: 40px;
    width: 206px;
    border: 1px solid #B8C5D0;
    border-radius: 26px;
    background-color: #FFF;
    line-height: 36px;
    padding-left: 24px;
}

.add-to-collection__button:before {
    font-family: FontAwesome, serif;
    content: '\f115';
    padding-right: 10px;
    font-size: 18px;
}

.modal-rating {
    text-align: center;
}

.ratingRow {
    unicode-bidi: bidi-override;
    direction: rtl;
}

.ratingRow > span:before {
    font-size: 24px;
    color: #FBB829;
    font-family: FontAwesome, serif;
    content: '\f006';
    cursor: pointer;

}

.ratingRow > span:hover:before, .ratingRow span:hover ~ span:before {
    content: '\f005';
}

.ratingStar {
    padding-right: 10px;
    margin-left: 15px;
}

.new-comment-with-comment,
.new-comment-without-comment {
    height: auto;
    width: 100%;
    border: 1px solid #B8C5D0;
    padding-left: 45px;
    line-height: 50px;
    font-size: 14px;
    outline: none;
}

.new-comment-with-comment {
    border-right: 0;
    border-radius: 25px 0 0 25px;
}

.new-comment-without-comment {
    border-radius: 25px;
}

.comment-input {
    display: flex;
    justify-content: space-between;
}

.comment-input > input:first-child {
    width: 100%;
}

.modal-comment {
    border-radius: 25px;
    height: auto;
    line-height: 40px;
    background-color: #FFF;
    padding-left: 45px;
}

.comment-button {
    font-size: 14px;
    padding: 5px 15px 5px 5px;
    border: 1px solid #B8C5D0;
    border-left: 0;
    border-radius: 0 25px 25px 0;
}

.comment-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.toprow-btn {
    text-align: center;
    cursor: pointer;
    margin: 20px;
}

.toprow-btn i {
    font-size: 22px;
}

.ratingDivider::before {
    font-size: 7px;
    padding-left: 10px;
    padding-right: 10px;
}

/* end modal.css */

/* taskSetModal.css */

.task-set-task-viewer-row {
    margin-bottom: 50px;
}

.task-set-modal-title__container {
    margin-bottom: 50px;
}

.task-set-modal-title {
    font-size: 25px;
    line-height: 25px;
}

.task-set-modal-title--icon {
    color: #093566;
    font-size: 22px !important;
    padding-right: 10px;
    padding-left: 10px;
}

.task-set-description {
    margin-top: 13px;
    margin-left: 42px;
}

.task-set-task-viewer-index {
    font-size: 18px;
    padding-bottom: 5px;
}

.task-set-form-top__content {
    padding: 0 10% 0 10%;
}

.task-set-form-grade--input {
    width: 250px;
}

.task-set-form-chosen-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-right: 6em;
    padding-left: 6em;
    margin-top: 30px;
}

.chosen-task-list-element {
    margin-bottom: 20px;
}

/* end taskSetModal.css */

/* TaskPicker.css*/
.task-picker__container {
    height: 100%;
    width: 100%;
    padding: 20px 20px 40px 20px;
}

.sort-dropdown--task-picker {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    float: right;
}

.task-picker__title {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 40px;
}

.task-picker__topbar {
    margin-bottom: 30px !important;
}

.task-picker__searchbar {
}

.task-picker-button__container {

}

.task-picker-button {
    height: 50px;
    width: 148px;
    border-radius: 25px;
    font-size: 14px;
    color: #698190;
    text-align: center;
    border: 1px solid #5E9E21;
    line-height: 48px;
    padding-left: 20px;
    padding-right: 20px;
    float: right;
    cursor: pointer;
}

.show-more-button__container {
    text-align: center;
    padding-top: 20px;
}

.show-more-button {
    cursor: pointer;
    padding: 6px;
}

.task-set__selector {
    width: 100%;
}

.task-set-select {
    width: 800px;
}

/*end TaskPicker.css*/

/* topBar.css */

.top-bar {
    height: 198px;
    padding-top: 48px;
    margin-bottom: 20px;
    position: relative;
}

.top-bar-button {
    height: 50px;
    border: 1px solid #B8C5D0;
    border-radius: 25px;
    text-align: center;
    float: left;
    cursor: pointer;
    position: relative;
}

.top-bar-button:hover {
    background-color: rgba(0, 0, 0, 0.02);
}

.add-to-button {
    width: 166px;
}

.add-to-button--icon {
    position: absolute;
    margin-left: -40px;
    margin-top: 10px;
}

.add-button-text,
.new-button-text {
    line-height: 46px;
}

.new-button--icon {
    position: absolute;
    margin-left: -28px;
    margin-top: 7px;
}

.top-bar-menu {
    margin-top: 10px;
    background-color: #ffffff;
    z-index: 1;
    width: 174px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}

.new-button {
    height: 41px;
    cursor: pointer;
    line-height: 40px;
    margin-left: 6px;

}

.modal-click:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.new-button:hover {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: inherit;
    opacity: 1 !important;
}

.new-button-text-menu {
    cursor: pointer;
    float: left;
    padding-left: 8px;
}

.grade-select--isModal {
    width: 30px;
}

.new-button-text-menu:before {
    font-family: FontAwesome, serif;
    padding-right: 10px;
    font-size: 15px;
    opacity: 0.72;
}

.taskIconCorner:before {
    content: '\F016';
}

.taskSetIconCorner:before {
    content: "\F0C5";
}

.collectionIconCorner:before {
    content: "\f115";
}

.favouritesIconCorner:before {
    content: "\F097"
}

.top-bar-search {
    max-width: 480px;
    width: 100%;
    float: right;
}

.sort__dropdown--options--task-picker .sort__dropdown--options {
    margin-right: 22px;
}

.sort__dropdown--option:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.sort-dropdown--top-bar {
    padding-right: unset;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    right: 10px;
    bottom: 36px;
}

.sort__dropdown {
    width: 100%;
}

.dropdown-button {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.dropdown-button--sort {
    justify-content: flex-end;
}

.dropdown-button--filter {
    width: 120px;
}

.dropdown-button--filter-modal {
    width: 100px;
}

.dropdown-button:after {
    font-family: FontAwesome, serif;
    content: '\f107';
    font-size: 20px;
    line-height: 24px;
    transition: all .3s;
    padding-left: 10px;
}

.dropdown-button--up:after {
    transform: rotateX(0);
}

.dropdown-button--down:after {
    transform: rotateX(180deg);
}

.sort__dropdown--options {
    position: absolute;
    right: 0;
    z-index: 2;
    width: 140px;
    text-align: right;
    background-color: white;
    padding: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);

}

.sort__dropdown--option {
    cursor: pointer;
    line-height: 30px;
    padding-right: 16px;
}

.sort__dropdown--option:hover {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: inherit;
    opacity: 1 !important;
}

/* end topBar.css*/

/* searchbar */

.searchbar {
    height: 50px;
    width: 100%;
    border: 1px solid #B8C5D0;
    border-radius: 25px;
    line-height: 50px;
    padding-left: 46px;
    font-size: 14px;
    outline: none;
}

.searchbar__container:before {
    font-family: FontAwesome, serif;
    content: '\f002';
    font-size: 20px;
    position: absolute;
    margin-left: 18px;
    margin-top: 8px;
}

/* end searchbar */

/* react-confirm-alert */

.react-confirm-alert--favouritted .react-confirm-alert {
    width: 30%;
}

.react-confirm-alert--favouritted .react-confirm-alert h1 {
    margin-bottom: 2em;
    text-align: center;
    font-size: 20px;
}

.react-confirm-alert--favouritted .react-confirm-alert-button-group button {
    margin: auto;
}

.react-confirm-alert--publisher .react-confirm-alert {
    width: 40%;
}

.react-confirm-alert--publisher .react-confirm-alert h1 {
    margin-bottom: 2em;
    text-align: center;
    font-size: 20px;
}

.react-confirm-alert--publisher .react-confirm-alert-button-group button {
    margin: auto;
}

/* end react-confirm-alert */

/* Filter grade */

.select-input--filter select {
    padding-left: 10px;
    font-size: 16px;
    border: none;
    overflow: hidden;
    width: 100%;
    height: 40px;
}

.filter-label {
    display: inline-block;
}

.filter-entity-row--modal-select select {
    max-width: 160px;
}

.filters {
    padding-top: 26px;
}

.select-input--filter:after {
    margin-left: -20px;
}

/* end Filter grade */

.checkbox-filter--difficulty input[type=checkbox]:before {
    padding-right: 6px;
}

.filter-checkbox__row {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    max-width: 250px;
}

.filter-entity-table {
    display: flex;
    flex-wrap: wrap;
}

.task-list-loader .sk-circle {
    height: 50px;
    width: 50px;
    margin: auto;
}

.subtask__container {
    position: relative;
}

.subtask-answer--removeicon {
    position: absolute;
    right: -20px;
    bottom: 20px;
}

.subtask-answer--removeicon:after {
    font-family: FontAwesome, sans-serif;
    content: '\f00d';
    align-self: center;
    cursor: pointer;
}

.empty-box__taskset {
    margin-bottom: 30px;
}

.subtask-rows {
    position: relative;
}

.top-bar-button .custom-dropdown-menu--active {
    position: absolute;
    max-height: unset;
}

.top-bar-button--new {
    width: 100px;
    margin-left: 20px;
}

.sort__dropdown .custom-dropdown-menu--active {
    position: absolute;
}

.sort-dropdown--top-bar .custom-dropdown-menu--active,
.sort-dropdown--task-picker .custom-dropdown-menu--active {
    position: absolute;
    max-height: unset;
}

.checkbox--label:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.task-set-new-task-set__button:hover {
    background-color: rgba(0, 0, 0, 0.02);
}

.task-picker-button:hover {
    background-color: rgba(0, 0, 0, 0.02);
}

.side-bar {
    margin-top: -36px;
    min-width: 135px;
    max-width: 320px;
}

.tabs {

}

.tabs ul {
    border-bottom: unset;

}

.tabs li {
    border-bottom: unset;
}

.tabs a {
    color: #093566;
}

.tabs li.is-active a {
    border-bottom: 3px solid #5E9E21;
    color: #093566;
}

.tabs a:hover {
    border-bottom: unset;
}

/* ------ */

.show-more__filters-btn {
    cursor: pointer;
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
}

.filter-top {
    padding-bottom: 20px;
}

.custom-dropdown-menu {
    visibility: hidden;
    transform: translateY(-100%);
    transition: all 0.2s;
    opacity: 0;
    max-height: 0;
}

.custom-dropdown-menu--active {
    visibility: visible;
    transition: all .5s cubic-bezier(0, 1.34, 1, 0.96);
    transform: translateY(0);
    opacity: 1;
    max-height: 100%;
}

.show-more__text, .grade-label {
    color: inherit;
    font-size: 14px;
}

/* ------ */

.filter-entity-row {
    margin-bottom: 30px;
}

.filter-entity-row--modal .filter-button {
    max-width: 160px;
}

.filter-entity-row--modal .filter-entity {
    grid-template-columns: repeat(auto-fill, 200px);
}

.filter-entity {
    display: grid;
    grid-template-columns: repeat(auto-fill, 100%);
    justify-content: space-between;
}

.filter-button {
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
}

.filter-button--small .filter-button {
    padding-top: 6px;
    padding-bottom: 6px;
}

.taskIconCorner:before {
    content: '\F016';
}

.taskSetIconCorner:before {
    content: "\F0C5";
}

.collectionIconCorner:before {
    content: "\f115";
}

.favouriteIconCorner:before {
    content: "\F097";
    margin-right: 5px;
}

.filter-button-label span {
    padding-left: 10px;
    align-self: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.filter-bottom {
    margin-top: 10px;
}

.filter-button-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px;
}

.filter-button-label:before {
    font-family: FontAwesome, serif;
    font-size: 15px;
    opacity: 0.72;
    position: absolute;
}

.filter-button-label:after {
    font-family: FontAwesome, serif;
    content: '\f096';
    font-size: 20px;
    padding-left: 8px;
}

.filter-button-label--checked:after {
    font-family: FontAwesome, serif;
    content: '\F14a';
    color: #5E9E21;
}

.filter-top--label .filter-button-label {
    padding: unset;
}

.filter-top--label .filter-button-label span {
    padding-left: 20px;
}

.custom-dropdown-menu-wrapper {
    overflow: hidden;
    pointer-events: none;
}

.dropdown-button:hover {
    border-bottom: solid 1px;
}

.filter-button:hover {
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 2px;
}

.custom-dropdown-menu-wrapper--active {
    pointer-events: unset;
}

.log-out-button {
    margin-top: 19px;
    margin-bottom: 29px;
    height: 30px;
    width: 91px;
    border-radius: 20px;
    background-color: #ED4337;
    font-size: 13px;
    border: 0;
    cursor: pointer;
}

.log-out-button a {
    color: #fff;
}

.info-border {
    box-sizing: border-box;
    border: 1px solid #B8C5D0;
}

.info-border--user {
    width: 145px;
}

.info-border--info {
    width: 340px;
}

.info-title {
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
    display: inline-block;
}

.info-title--telephone:before {
    font-family: FontAwesome, sans-serif;
    content: '\f095';
    margin-right: 5px;
}

.info-exit-icon {
    position: absolute;
    cursor: pointer;
    top: 6px;
    right: 6px;
}

.header__overlay-box {
    position: absolute;
    z-index: 10;
    right: 68px;
    top: 65px;
}

.header__overlay-box--profileinfo {
    right: 90px;
}

.logout-button {
    border: none;
    margin-top: 10px;
    height: 16px;
    width: 75px;
    color: #698190;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
}

.delete-user-button {
    border: none;
    margin-top: 10px;
    margin-left: 50px;
    height: 16px;
    width: 140px;
    color: #698190;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
}

.close-button {
    position: absolute;
}

.close-button--info {
    margin-left: 340px;
    margin-top: -10px;
}

.close-button--user {
    margin-left: 140px;
    margin-top: -10px;
}

.header-icon {
    border-radius: 40px;
    cursor: pointer;
}

.header-icon--user {
    background-color: rgba(251, 184, 41, 0.2);
}

.header-icon--info {
}

.header-icon__container {
    height: 40px;
    width: 40px;
    float: right;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 10;
}

.header-icon__container--user {
    margin-right: 2%;
}

.header-icon__container--info {
    margin-right: 0.5%;
    background-color: rgba(230, 239, 223, 1);
    border-radius: 40px;
    z-index: 1;
    right: 18px;
    cursor: pointer;
}

.header-icon__container--info img {
    height: 14px;
    width: 14px;
    margin: auto;
}

/*Rating*/
.left-bottom--icon {
    float: left;
    padding: 10px 15px 15px 0;
    margin-left: 20px;
}

.card-rating {
    float: left;
    padding: 10px 15px 15px 0;
}

.fa.fa-star {
    font-size: 22px;
    color: #F5A623;
}

.modal-rating {
    text-align: center;
}

.ratingRow {
    unicode-bidi: bidi-override;
    direction: rtl;
}

.ratingRow > span:before {
    font-size: 24px;
    color: #FBB829;
    font-family: FontAwesome, serif;
    content: '\f006';
    cursor: pointer;

}

.ratingRow > span:hover:before, .ratingRow span:hover ~ span:before {
    content: '\f005';
}

.ratingStar {
    padding-right: 10px;
    margin-left: 15px;
}

/*Homemade css*/
.header {
    height: 65px;
    width: 100%;
    background-color: rgba(231, 240, 223, 0.7);
}

.skolon-menu-button {
    z-index: 10;
}

.loginBtn {
    position: relative;
    width: 250px;
    padding: 5px 5px 5px 5px;
    border: none;
    text-align: left;
    white-space: nowrap;
    font-size: 16px;
    color: #FFF;
    margin-bottom: 15px;
    border-radius: 5px;
    height: 40px;
}
.loginBtn:focus {
    outline: none;
}
.loginBtn:active {
    box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}

.loginBtn--facebook {
    background-color: #4C69BA;
    background-image: linear-gradient(#4C69BA, #3B55A0);
    text-shadow: 0 -1px 0 #354C8C;
}

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
    background-color: #5B7BD5;
    background-image: linear-gradient(#5B7BD5, #4864B1);
}

.loginBtn--skolon {
    background-color: #a5a5c0;
    background-image: linear-gradient(#a5a5c0, #7878a1);
    text-shadow: 0 -1px 0 #a5a5c0;
}

.loginBtn--skolon:hover,
.loginBtn--skolon:focus {
    background-color: #a5a5c0;
    background-image: linear-gradient(#a5a5c0, #7878a1);
}

.header__title {
    text-align: center;
    padding-top: 5px;
    max-width: 571px;
    margin: auto;
}

.header__subtitle {
    margin-top: 16px;
}

.lineHeader:after {
    height: 2px;
    width: 45px;
    border-bottom: 2px solid #5E9E21;
    content: '';
    position: absolute;
    margin-left: -22.5px;
    margin-top: 11px;
}

.copyright {
    color: #fff;
    margin-right: 10px;
    font-size: 12px;
}

/*Bulma.io css override*/

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: #6a8292;
}
