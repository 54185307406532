#export-pdf-container {
    position: relative;
}

#export-pdf-btn {
    width: 120px;
    height: 40px;
}

#solution-checkbox {
    margin: 10px;
    width: 20px;
    font-size: 20px;
}

#task-set-pdf {
    margin-top: 20px;
    text-align: left;
}

.task-set-pdf-header-big {
    font-size: 30px;
}

.task-set-pdf-header-medium {
    font-size: 20px;
}

.task-set-pdf-header-medium-bold {
    font-size: 20px;
    font-weight: bold;
}

#task-set-pdf {
    display: none;
}

.task-set-pdf-sub-task {
    padding-left: 26px;
    font-size: 20px;
}

.task-set-pdf-formula {
    margin-top: 20px;
    margin-bottom: 20px;
}

.pdf-task-container {
    font-size: 30px;
}

.task-set-task-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
}

.task-hint, .task-link, .task-tools {
    font-size: 20px;
    padding-top: 10px;
}

.task-description {
    font-size: 26px;
    clear: both;
    padding: 4px 0 4px 4px;
}
