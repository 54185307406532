.generate-button-container {
    display: flex;
    justify-content: flex-end;
}

.generate-button-button {
    margin: 20px 0 20px 20px;
    height: 40px;
    width: 125px;
    border-radius: 25px;
    color: #FFFFFF;
    background-color: #5E9E21;
}

.generate-button-button:disabled {
    background-color: #B8C5D0;
    cursor: default;
}
