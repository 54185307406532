.options {
    display: flex;
}

.option:first-child {
    border-radius: 20px 0 0 20px;
    border-left: 1px solid black;
}

.option:last-child {
    border-radius: 0 20px 20px 0;
}

.option {
    text-align: center;
    padding-top: 10px;
    border: 1px solid black;
    border-left: none;
    height: 40px;
    margin-top: 20px;
}

.option-checked {
    background-color: #093566;
    color: #FFFFFF;
}

.radio-button-title {
    padding-left: 20px;
}