.custom-task-amount {
    width: 200px;
}

.custom-task-amount-input {
    border: 1px solid #B8C5D0;
    border-radius: 25px;
    height: 40px;
    padding-left: 20px;
    font-size: 16px;
    outline: none;
    margin-top: 40px;
}