.task-format-preview-container {
    margin-top: 20px;
}

.task-format-preview {
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    padding: 30px;
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 40px;
}
