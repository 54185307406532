.task-viewer__container {
    width: 100%;
    padding: 20px 40px 40px 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    background-color: #FFF;
}

.task-viewer--icon {
    position: absolute;
    margin-top: 24px;
    margin-left: 14px;
    font-size: 18px !important;
}

.task-viewer-link,
.task-viewer-tool {
    margin-top: 20px;
    width: 50%;
}

.task-viewer-link:before {
    font-family: FontAwesome, serif;
    content: '\f0c1';
    padding-right: 10px;
    font-size: 18px;
}

.task-viewer-tool:before {
    font-family: FontAwesome, serif;
    content: '\f1ec';
    padding-right: 10px;
    font-size: 18px;
}

.task-viewer-solution__container,
.task-viewer-hint__container {
    padding: 8px 8px 8px 8px;
    border: 1px solid #B8C5D0;
    border-radius: 9px;
    width: 40%;
    margin-top: 20px;
}

.solution {
    text-align: right;
}

.checkbox-include-solution {
    padding-left: 30px;
    width: 170px;
}

.checkbox-include-solution input {
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkbox-include-solution input[type=checkbox]:before {
    font-family: FontAwesome, serif;
    content: '\f1db';
    font-size: 22px;
    padding-right: 18px;
    color: #093566;
}

.checkbox-include-solution input[type=checkbox]:checked:before {
    content: '\f058';
    color: #5E9E21;
}

.sharelink-row {
    align-items: center;
}