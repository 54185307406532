.form-container {
    display: flex;
}

.formula-input, .show-input-button {
    width: 100%;
    border-top: none !important;
    border-radius: 0 0 6px 6px;
}

.show-input-button {
    width: 100%;
    padding: 8px 8px 8px 16px;
    text-align: left;
}

.show-input-button::before {
    font-family: FontAwesome, serif;
    content: '\f12b';
    font-size: 18px;
    padding-right: 4px;
}

.formula-input {
    border: 1px solid #B8C5D0;
    padding: 6px;
}