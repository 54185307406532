.new-task-set {
    display: flex;
    flex-direction: column;
}

.new-task-set-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new-task-set__title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.new-task-set__title {
    padding: 6px;
}

.new-task-set__description {
    width: 50%;
    padding: 16px;
    align-items: center;
}

.new-task-set__title-input {
    width: 100%;
}

.new-task-set-dropdowns {
    display: flex;
    align-items: left;
}

.new-task-set-dropdown-menu {
    margin-right: 20px;
}

.task-set-title-container {
    width: 100%;
    height: 40px;
    padding-left: 20px;
    border: 1px solid #03235A;
    font-size: 15px;
    padding-top: 7px;
}

.task-set-container {
    margin-top: 20px;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
    padding: 20px 100px 20px 100px;
}

.task-set-header {
    text-align: center;
}

.task-set-preview-description {
    margin-top: 20px;
    padding-top: 10px;
    padding-left: 20px;
    border: 1px solid #03235A;
    width: 100%;
    height: 80px;
}

.new-task-set-preview {
    padding: 20px 100px 20px 100px;
}

.tasks-in-taskset-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

.task-container {
    font-size: 18px;
    width: 50%;
    padding: 10px;
}

.back-button {
    margin: 20px 0 20px 0;
    height: 40px;
    width: 125px;
    border-radius: 25px;
    color: #03235A;
    border: 1px solid #03235A;
    background-color: #FFFFFF;
}

.tasks-preview-options-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.more-options-button {
    margin: 20px 0 20px 20px;
    height: 40px;
    width: auto;
    padding: 0 20px 0 20px ;
    border-radius: 25px;
    color: #FFFFFF;
    background-color: #03235A;
}
