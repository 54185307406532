.report-modal {
    width: auto;
    height: 250px;
    background-color: blue;
    z-index: 10000;
    position: fixed;
    left: 38%
}

.report-modal-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.4;
    background-color: black;
}

.report-textarea {
    width: 100%;
    height: 100px;
    resize: none;
}

.report-header {
    margin-bottom: 15px;
}
